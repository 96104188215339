@import url(https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  background-color: #082032;
  color: white;
  scroll-behavior: smooth;
}

main {
  min-height: calc(100vh - 80px - 0.3rem - 3.125rem);
}

button,
input,
textarea {
  font-family: "Titillium Web";
}

.underline-a {
  position: relative;
}

.underline-a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  transition: all 0.2s ease-in-out;
  border-top: 1px solid white;
}

.underline-a:hover::after {
  width: 100%;
}

header {
  height: 80px;
  z-index: 100;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Titillium Web", sans-serif;
  margin-top: 0.3rem;
  padding: 0 1.5rem;
}

header > a {
  height: 70%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

header > a > img {
  height: 100%;
  object-fit: contain;
  transition: all 0.18s ease-in-out;
}

header > a > img:hover {
  opacity: 0.7;
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

header > nav > ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

header > nav > ul > li {
  margin-left: 1.5em;
}

header > nav > ul > li > a {
  text-decoration: none;
  color: white;
  font-size: 1.4em;
}

.burger {
  width: 32px;
  height: 22px;
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}

.burger > .line {
  width: 100%;
  height: 2px;
  background-color: white;
}

.Nav__header__background {
  -webkit-transform: translateY(-150px);
          transform: translateY(-150px);
  transition: all 0.6s ease-in-out;
  opacity: 0;
  z-index: -10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2c394b69;
}

@media screen and (max-width: 500px) {
  .Nav__nav__ul {
    opacity: 0;
    -webkit-transform: translateY(-150px);
            transform: translateY(-150px);
    transition: all 0.6s ease-in-out;
    pointer-events: none;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #172a3c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0.75em 0;
  }
  .Nav__nav__ul > li {
    margin-left: 0;
    text-align: center;
  }
  .Nav__nav__ul > li:not(:first-child) {
    margin-top: 1em;
  }
  .burger {
    display: flex;
  }
}

@media screen and (min-width: 501px) {
  .Nav__nav__ul {
    opacity: 1 !important;
    -webkit-transform: translateY(0) !important;
            transform: translateY(0) !important;
    pointer-events: all !important;
    flex-direction: row !important;
  }
}
/*# sourceMappingURL=styles.css.map */
footer {
  font-family: "Titillium Web", sans-serif;
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #203042;
}
/*# sourceMappingURL=styles.css.map */
.Home__main__projectsSection__projects__project {
  font-family: "Titillium Web";
  color: white;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  margin: 1rem;
  border-radius: 15px;
  overflow: hidden;
  background-color: #203042;
  width: 20.71875rem;
  height: 12.75rem;
  display: flex;
  flex-direction: column;
}

.Home__main__projectsSection__projects__project:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.Home__main__projectsSection__projects__project__imgDiv {
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.Home__main__projectsSection__projects__project__imgDiv > img {
  width: 100%;
  object-fit: contain;
  display: block;
}

.Home__main__projectsSection__projects__project__imgDiv__categoryDiv {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 2rem;
  padding: 0 1.2rem;
  background-color: #ff4c29;
  position: absolute;
  bottom: 0;
  left: 5%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  align-items: center;
}

.Home__main__projectsSection__projects__project__imgDiv__categoryDiv > p {
  font-weight: 600;
  font-size: 0.75rem;
}

.Home__main__projectsSection__projects__project > p {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}
/*# sourceMappingURL=styles.css.map */
.Home__main {
  display: flex;
  flex-direction: column;
  font-family: "Titillium Web", sans-serif;
}

.Home__main__heroSection {
  display: flex;
  min-height: calc(100vh - 80px - 0.3rem);
  position: relative;
}

.Home__main__leftSide {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home__main__rightSide {
  flex: 1 1;
}

.Home__main__leftSide__textAndButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Home__main__leftSide__textAndButtons__contactmeBtn {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  color: white;
  cursor: pointer;
  background: #ff4c29;
  border-radius: 8px;
  font-size: 1.2rem;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-align: center;
  width: calc(12rem + 3px);
  min-height: calc(2.8rem + 3px);
  padding: 0.1rem;
  transition: all 0.15s ease-in-out;
  border: 0.15rem solid #ff4c29;
}

.Home__main__leftSide__textAndButtons__contactmeBtn:hover {
  background: transparent;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.Home__main__leftSide__textAndButtons__text {
  margin-bottom: 2.2rem;
  text-align: center;
}

.Home__main__leftSide__textAndButtons__text > h1 {
  font-size: 3.5rem;
  margin-bottom: 0.4rem;
}

.Home__main__leftSide__textAndButtons__text > p {
  font-size: 1.15rem;
}

.Home__main__rightSide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home__main__rightSide__img {
  height: 60vh;
  object-fit: contain;
}

.Home__main__learnMore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0.7rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  font-size: 1rem;
  -webkit-animation: learnMore 3s infinite;
          animation: learnMore 3s infinite;
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

.Home__main__learnMore > a > img {
  margin-top: 0.2rem;
  cursor: pointer;
  height: 1.1rem;
}

.Home__main__learnMore > a {
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.Home__main__aboutmeSection__aboutme__text > hr {
  height: 0.08rem;
  background-color: #ff4c29;
  border: none;
}

@-webkit-keyframes learnMore {
  0%,
  40%,
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  20% {
    -webkit-transform: translate(-50%, -0.6rem);
            transform: translate(-50%, -0.6rem);
  }
}

@keyframes learnMore {
  0%,
  40%,
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  20% {
    -webkit-transform: translate(-50%, -0.6rem);
            transform: translate(-50%, -0.6rem);
  }
}

@media screen and (min-width: 1024px) {
  html {
    font-size: 19px;
  }
}

@media screen and (min-width: 1756px) {
  .Home__main__rightSide__img {
    height: 65vh;
  }
  html {
    font-size: 22px;
  }
}

@media screen and (min-width: 2050px) {
  .Home__main__rightSide__img {
    height: 70vh;
  }
  html {
    font-size: 26px;
  }
}

@media screen and (min-width: 2360px) {
  html {
    font-size: 29px;
  }
}

@media screen and (min-width: 2700px) {
  html {
    font-size: 32px;
  }
}

@media screen and (max-width: 1200px) {
  .Home__main__rightSide {
    display: none;
  }
  .Home__main__heroSection {
    position: relative;
    z-index: 10;
  }
  .Home__main__heroSection::after {
    content: "";
    z-index: -10;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("/static/media/Nerdy Programmer.5298d258.png");
    background-size: contain;
    opacity: 0.08;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (max-width: 450px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 13px;
  }
}

.Home__main__projectsSection {
  display: flex;
  border-top: 1px solid #203042;
  border-bottom: 1px solid #203042;
  flex-direction: column;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  align-items: center;
  padding-bottom: 1rem;
}

.Home__main__projectsSection__title {
  margin: 1.5rem 0 0.5rem 0;
  font-size: 2.5rem;
  text-align: center;
}

.Home__main__projectsSection__projects {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.Home__main__projectsSection__allProjectsBtn {
  outline: none;
  margin: 1rem 0 2rem 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.1rem;
  width: calc(16rem + 3px);
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-align: center;
  min-height: calc(2.8rem + 3px);
  padding: 0.1rem;
  background: transparent;
  transition: all 0.15s ease-in-out;
  font-size: 1.05rem;
  border: 0.15rem solid #203042;
}

.Home__main__projectsSection__allProjectsBtn:hover {
  border: 0.15rem solid #ff4c29;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

@media screen and (max-width: 735px) {
  .Home__main__projectsSection__projects__project {
    width: 24.8625rem;
    height: 15.3rem;
  }
  .Home__main__projectsSection__projects__project > p {
    font-size: 1.2rem;
  }
}

.Home__main__aboutmeSection {
  display: flex;
  padding: 0 2rem 1rem 2rem;
}

.Home__main__aboutmeSection__aboutme {
  flex: 1 1;
}

.Home__main__aboutmeSection__aboutme__title {
  text-align: center;
  font-size: 2.5rem;
  margin: 1.5rem 0 1.5rem 0;
}

.Home__main__aboutmeSection__aboutme__text {
  line-height: 2.2rem;
}

.Home__main__aboutmeSection__skills {
  flex: 1 1;
}

.Home__main__aboutmeSection__skills__title {
  text-align: center;
  font-size: 2.5rem;
  margin: 1.5rem 0 1.5rem 0;
}

.Home__main__aboutmeSection__skills {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Home__main__aboutmeSection__skills__progressbars {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Home__main__aboutmeSection__skills__progressbars__progressbar {
  display: flex;
  margin-bottom: 1rem;
}

.Home__main__aboutmeSection__skills__progressbars__progressbar__language {
  background-color: #ff4c29;
  width: 8rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.9rem;
}

.Home__main__aboutmeSection__skills__progressbars__progressbar__bar {
  position: relative;
  width: 100%;
  text-align: end;
}

.Home__main__aboutmeSection__skills__progressbars__progressbar__bar > p {
  position: absolute;
  top: 50%;
  right: 0.4rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 20;
}

.Home__main__aboutmeSection__skills__progressbars__progressbar__bar > div {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background-color: #ff1d08;
}

.Home__main__aboutmeSection__skills__progressbars__progressbar__bar::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(51, 71, 87, 0.65);
}

@media screen and (max-width: 1250px) {
  .Home__main__aboutmeSection {
    flex-direction: column;
  }
  .Home__main__aboutmeSection__aboutme {
    width: 75%;
    margin: 0 auto;
  }
  .Home__main__aboutmeSection__skills__progressbars__progressbar {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .Home__main__aboutmeSection {
    flex-direction: column;
  }
  .Home__main__aboutmeSection__aboutme {
    width: 90%;
    margin: 0 auto;
  }
  .Home__main__aboutmeSection__skills__progressbars {
    width: 90%;
  }
}

@media screen and (max-width: 365px) {
  .Home__main__aboutmeSection {
    flex-direction: column;
  }
  .Home__main__aboutmeSection__aboutme {
    width: 95%;
    margin: 0 auto;
  }
  .Home__main__aboutmeSection__skills__progressbars {
    width: 95%;
  }
}

.Home__main__contactmeSection {
  display: flex;
  min-height: calc(100vh - 3.125rem);
  border-top: 1px solid #203042;
}

.Home__main__contactmeSection__details {
  flex: 1 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.Home__main__contactmeSection__details__title {
  font-size: 2.5rem;
  margin: 1.5rem 0 1.5rem 0;
}

.Home__main__contactmeSection__details__infoText {
  margin-bottom: 1.5rem;
}

.Home__main__contactmeSection__details__iconsWithExtraDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 13rem;
  margin-bottom: 1.5rem;
}

.Home__main__contactmeSection__details__iconsWithExtraDetails__box {
  color: white;
  text-decoration: none;
  display: flex;
  border: 0.125rem solid #203042;
  border-radius: 15px;
  height: 3.4rem;
  width: 22rem;
  align-items: center;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.Home__main__contactmeSection__details__iconsWithExtraDetails__box > svg {
  height: 1.5rem;
  object-fit: contain;
  padding: 0 1rem;
  fill: #ff4c29;
}

.Home__main__contactmeSection__details__iconsWithExtraDetails__box__text {
  font-size: 1.1rem;
}

.Home__main__contactmeSection__details__iconsWithExtraDetails__box:hover, .Home__main__contactmeSection__details__iconsWithExtraDetails__box:focus {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  border: 0.1875rem solid #ff4c29;
}

.Home__main__contactmeSection__details__socialmediaIcons {
  display: flex;
  justify-content: space-around;
  width: 22rem;
  margin-bottom: 1.5rem;
}

.Home__main__contactmeSection__details__socialmediaIcons a {
  outline: none;
}

.Home__main__contactmeSection__details__socialmediaIcons a:focus svg {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  fill: #ff4c29;
}

.Home__main__contactmeSection__details__socialmediaIcons svg {
  height: 2rem;
  object-fit: contain;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  fill: #203042;
}

.Home__main__contactmeSection__details__socialmediaIcons svg:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  fill: #ff4c29;
}

.Home__main__contactmeSection__formSide {
  flex: 1 1;
}

.Home__main__contactmeSection__formSide__form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 27.625rem;
  height: 32rem;
  background-color: #203042;
  border-radius: 15px;
  margin: 1.5rem auto;
  max-width: 90%;
}

.Home__main__contactmeSection__formSide__form__container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Home__main__contactmeSection__formSide__form__container__buttoncontainer {
  flex: 0.3 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Home__main__contactmeSection__formSide__form__container__buttoncontainer > button {
  height: 2.3rem;
  width: 8rem;
  border: 0.125rem solid #334756;
  border-radius: 0.4rem;
  background: transparent;
  font-size: 0.8rem;
  color: white;
  font-family: "Titillium Web";
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.Home__main__contactmeSection__formSide__form__container__buttoncontainer > button:hover, .Home__main__contactmeSection__formSide__form__container__buttoncontainer > button:focus {
  border: 0.13rem solid #ff4c29;
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.Home__main__contactmeSection__formSide__form__fields {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 2 1;
}

.Home__main__contactmeSection__formSide__form__field {
  display: flex;
  flex-direction: column;
}

.Home__main__contactmeSection__formSide__form__field > label {
  margin-bottom: 0.4rem;
}

.Home__main__contactmeSection__formSide__form__field > input,
.Home__main__contactmeSection__formSide__form__field textarea {
  width: 24rem;
  background: transparent;
  outline: none;
  border: 0.125rem solid #334756;
  border-radius: 0.4rem;
  color: white;
  font-size: 0.9rem;
  padding: 0.6rem;
  font-family: "Titillium Web";
  transition: border 0.25s ease-in-out;
}

.Home__main__contactmeSection__formSide__form__field > input:focus,
.Home__main__contactmeSection__formSide__form__field textarea:focus {
  border: 0.13rem solid #ff4c29;
}

.Home__main__contactmeSection__formSide__form__field > input {
  height: 2.5rem;
}

.Home__main__contactmeSection__formSide__form__field > textarea {
  height: 10rem;
  line-height: 1.1rem;
}

.Home__main__contactmeSection__formSide__form {
  max-width: 90%;
}

.Home__main__contactmeSection__formSide__form__field > input,
.Home__main__contactmeSection__formSide__form__field textarea {
  max-width: 100%;
}

.Home__main__contactmeSection__formSide__form__container {
  max-width: 90%;
}

@media screen and (max-width: 1070px) {
  .Home__main__contactmeSection {
    display: flex;
    flex-direction: column;
  }
  .Home__main__contactmeSection__details {
    align-items: center;
  }
  .Home__main__contactmeSection__details__infoText {
    text-align: center;
    max-width: 90%;
  }
}

.statusDiv {
  width: 100%;
  margin: 1rem 0 0.2rem 0;
  padding: 0.8rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.4rem;
}

.statusDiv > p {
  text-align: center;
  width: 100%;
  font-size: 1.1rem;
}
/*# sourceMappingURL=styles.css.map */
.Allprojects__main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filters {
  height: 3em;
  max-width: 90%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 0.1em solid #334756;
  border-radius: 0.45em;
  margin: 2em 0;
  overflow: hidden;
}

.filters > ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  list-style: none;
  font-family: "Titillium Web", sans-serif;
}

.filters > ul > li {
  overflow: hidden;
  padding: 0 2em;
  font-size: 0.95em;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.filters > ul > li:hover, .filters > ul > li:focus, .filters > ul > li.active {
  background-color: #ff4c29;
}

.filters > ul > li:first-child {
  border-right: 0.1em solid #334756;
}

.filters > ul > li:last-child {
  border-left: 0.1em solid #334756;
}

.filters > ul > li:not(:first-child):not(:last-child):not(:nth-last-child(2)) {
  border-right: 0.1em solid #334756;
}

.Allprojects__main__projects {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

@media screen and (max-width: 550px) {
  .filters {
    font-size: 14px;
  }
  .filters > ul > li {
    padding: 0 1em;
  }
}

@media screen and (max-width: 440px) {
  .filters {
    font-size: 12px;
  }
}

@media screen and (max-width: 380px) {
  .filters {
    font-size: 10px;
  }
}

@media screen and (max-width: 325px) {
  .filters {
    font-size: 9px;
  }
}

@media screen and (max-width: 285px) {
  .filters {
    font-size: 8px;
  }
}
/*# sourceMappingURL=styles.css.map */
.PageNotFound__main__title,
.PageNotFound__main__goToHomeLink {
  font-family: "Titillium Web";
  text-align: center;
}

.PageNotFound__main__title {
  font-size: 2.8rem;
}

.PageNotFound__main__goToHomeLink {
  margin-top: 0.8rem;
  font-size: 1.15rem;
  font-weight: normal;
  text-decoration: none;
  color: white;
}

.PageNotFound__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*# sourceMappingURL=styles.css.map */
