@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  background-color: #082032;
  color: white;
  scroll-behavior: smooth;
}

main {
  min-height: calc(100vh - 80px - 0.3rem - 3.125rem);
}

button,
input,
textarea {
  font-family: "Titillium Web";
}

.underline-a {
  position: relative;
}

.underline-a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  transition: all 0.2s ease-in-out;
  border-top: 1px solid white;
}

.underline-a:hover::after {
  width: 100%;
}
